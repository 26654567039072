import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import '../App.css';


const Success = () => {
  
  return (
    <div className="container success-container">
     
      <h1 className="success-message text-center anim-text">Transaction Successful</h1>
      <p className='success-text'>Your payment has been successfully processed! <br/> Your transaction will be completed within 10 banking days.<br/> Thank you for your co-operation</p>
      <RouterLink to="/">
        {/* <button>Go to Home</button> */}
      </RouterLink>
    </div>
  );
};

export default Success;
