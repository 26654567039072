import React from 'react';
import { useNavigate } from 'react-router-dom';
import cardImg from '../images/cardIcon.png'
import '../step1.css';

const Step1 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/step2');
  };

  return (
    <div className='container'>
        <div className='wrapper_step1'>
          <h1 className='text-center'>Press the button to proceed</h1>
          {/* <img src={cardImg} alt="Card Icon" /> */}
          <button className='button' onClick={handleNext}>Confirm</button>
        </div>
    </div>
  );
};

export default Step1;
