import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Step2 = () => {
  const navigate = useNavigate();
  const [currency, setCurrency] = useState('USD');

  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required('It is necessary to specify the amount')
        .min(200, 'The amount must be more than 200'),
    }),
    onSubmit: (values) => {
      navigate('/step3', { state: { amount: values.amount, currency } });
    },
  });

  return (
    <div className='container'>
      <h1 className='text-center'>Entered the amount</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className='input_wrapper3'>
        <div className='input_wrapper amount'>
          <label htmlFor="amount">Amount</label>
          <input
            id="amount"
            name="amount"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.amount}
          />
          {formik.touched.amount && formik.errors.amount ? (
            <div className='errors'>{formik.errors.amount}</div>
          ) : null}
          <select
            id="currency"
            name="currency"
            onChange={(e) => setCurrency(e.target.value)}
            value={currency}
          >
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
          </select>
        </div>
        <div className='input_wrapper'>
          {/* <label htmlFor="currency">Currency</label> */}
          
        </div>
        </div>
        <button className='button w100' type="submit">Confirm</button>
      </form>
    </div>
  );
};

export default Step2;
