import React from 'react'

export const Footer = () => {
  return (
    <footer>
      <div className='container'>

      </div>
    </footer>
  )
}
